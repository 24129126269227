import { useContext, useState } from "react";
import { Box, Flex, Text, TransparentButton } from "flicket-ui";
import { Icon, Status } from "~components";
import { BaseContext } from "~context";
import { Layout } from "~components/common/Layout";
import { EventAccessStatus, EventQuery } from "~graphql/sdk";
import { Desktop } from "./detail.Desktop";
import { Mobile } from "./detail.Mobile";
import { useIsMobile } from "~hooks";
import MainCTA from "../events.MainCTA";
import useShowReleaseCodes from "~features/events/hooks/useShowReleaseCodes";
import useDetailsPageContext, {
  competitionFromPageContext,
} from "~features/events/hooks/useDetailsPageContext";
import { Release } from "~graphql/fetchers";
import { useActiveCompetitions } from "~features/events/hooks/useActiveCompetitions";
import ReleaseCodesModal from "../ReleaseCodesModal";
import usePageLoad from "~telemetry/traces/usePageLoad";

import { AttributeNames } from "~telemetry/enums/AttributeNames";
import HeaderAlertBanner from "~components/common/common.HeaderAlertBanner";

export type DetailsProps = {
  event: EventQuery["event"];
  release?: Release;
  accessType: EventAccessStatus;
};

export const Detail = ({ event, release, accessType }: DetailsProps) => {
  const { activeCompetitions, isLoading } = useActiveCompetitions(event.id);
  const pageContext = useDetailsPageContext(event, release, activeCompetitions);
  const [activeModal, setActiveModal] = useState<"releaseCodes">();

  const isMobile = useIsMobile();
  const { isSinglePage } = useContext(BaseContext);
  const showReleaseCodes = useShowReleaseCodes(event.enableReleaseCodes);

  const competitionId = competitionFromPageContext(pageContext)?.id;

  const footerHeight = showReleaseCodes ? "110px" : "70px";

  usePageLoad(
    "event-details-page",
    {
      forwardToNest: true,
      eventId: event.id,
      attributes: {
        [AttributeNames.FLICKET_EVENT_DETAILS_PAGE_STATE]: pageContext.state,
        [AttributeNames.FLICKET_COMPETITION_ID]: competitionId,
      },
    },
    // Only fire the event after the active competitions have finished loading so that the pageContext is correct
    !isLoading
  );

  return (
    <Status loading={isLoading} error={false}>
      {isMobile && (
        <>
          <Box
            d={{ _: "flex", md: "none" }}
            flexDirection="column"
            flex={1}
            pt={isSinglePage ? 15 : 0}
          >
            {!isSinglePage && (
              <>
                <Box
                  position={"fixed"}
                  zIndex={"popover"}
                  bg="white"
                  top={0}
                  width={"100%"}
                >
                  <Flex
                    px={2}
                    py={2}
                    alignItems={"center"}
                    flex={1}
                    boxShadow={
                      "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)" as any
                    }
                  >
                    <TransparentButton href="/events">
                      <Icon icon="chevron-left" fontSize={6} />
                    </TransparentButton>
                    <Text
                      lineHeight="normal"
                      fontSize={3}
                      fontWeight="extraBold"
                      color="N800"
                      ml={2}
                    >
                      {event.title}
                    </Text>
                  </Flex>

                  <HeaderAlertBanner />
                </Box>
              </>
            )}
            <Box
              display={"flex"}
              flex={1}
              flex-direction={"column"}
              position={"relative"}
              width={"100%"}
              maxWidth={"100vw"}
              pt={isSinglePage ? 0 : 6}
              paddingBottom={footerHeight as any}
            >
              <Mobile pageContext={pageContext} />
            </Box>
            <Flex
              height={footerHeight}
              flexDirection={"column"}
              justifyContent={"center"}
              position={"fixed"}
              bottom={0}
              left={0}
              right={0}
              px={2}
              py={1}
              background="white"
              borderTop={"1px solid"}
              borderColor={"N200"}
            >
              <MainCTA pageContext={pageContext} />
              {showReleaseCodes && (
                <TransparentButton
                  onClick={() => setActiveModal("releaseCodes")}
                >
                  <Text
                    fontWeight={"extraBold"}
                    fontSize={3}
                    color="N800"
                    my={"6/4"}
                  >
                    Have an access code?
                  </Text>
                </TransparentButton>
              )}
            </Flex>
          </Box>
        </>
      )}
      {!isMobile && (
        <Box d={{ _: "none", md: "flex" }} flexDirection="column" flex={1}>
          <Layout layoutType="main">
            <Desktop
              pageContext={pageContext}
              event={event}
              isSinglePage={isSinglePage}
            />
          </Layout>
        </Box>
      )}

      <ReleaseCodesModal
        isOpen={activeModal === "releaseCodes"}
        onClose={() => setActiveModal(undefined)}
        event={event}
      />
    </Status>
  );
};
